import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const Logophilia = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Logophilia Rising" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Logophilia Rising</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Feb. 9, 2022) </p>
        </div>
        <div className="articleBody">
          <p>
            At the top of this year, I corresponded via email with someone whose
            work and style I’ve admired since I was 13 years old (Assterman’s
            finest, once the Chazebralope, he who drawls in perpetuity). This
            myth appeared to me the way that myths do — that is, out of thin
            air, to give encouragement you didn’t know you needed and approval
            only they’re qualified to give...all between conquering worlds
            entirely unknown to you.
          </p>
          <p>
            Anyway something he took notice of about this here corner of the{" "}
            <a
              href="https://en.wikipedia.org/wiki/Internet"
              target="__blank"
              rel="noopener noreferrer"
            >
              Internet
            </a>{" "}
            as compared to many others is the complete absence of imagery. I was
            excited by that, cuz it’s a deliberate choice that I always hoped
            readers would pick up on. Here go a couple of the reasons for that
            choice:
          </p>
          <p>
            1. It’s my subtle form of protest against the millisecond
            consumption cycle that Big Media provokes via constant imagery on
            socials (except{" "}
            <a
              href="https://minus.social/"
              target="__blank"
              rel="noopener noreferrer"
            >
              this one
            </a>
            , read{" "}
            <a
              href="https://bengrosser.com/projects/minus/"
              target="__blank"
              rel="noopener noreferrer"
            >
              all about it
            </a>
            ).
            <br />
            <br />
            2. I’ve loved words since I was a kid. I’m talking bout how they
            sound, how they’re spelled, where they come from, what they mean,
            new ones, old ones, slang, technical jargon, don’t matter the
            language.
          </p>
          <p>
            Now don’t get me wrong, I love visuals too, but these days pixels
            and letters are like a flood and a drought (I know letters are made
            up of pixels too sometimes but you get the analogy, don’t be so
            pedantic, don’t be so punctilious, don’t be so joe damn!!! lol). And
            there’s nothing like finding the exact right word to convey what you
            think or feel, or forgetting a word that was on the tip of your
            tongue and having it return to you spontaneously hours or days
            later.
          </p>
          <p>And I find that as a logophile, I’m in excellent company.</p>
          <p className="highlight">Kameelah Janan Rasheed</p>
          <p>
            I discovered Kameelah Janan Rasheed and her work through{" "}
            <a
              href="https://art21.org/watch/new-york-close-up/kameelah-janan-rasheed-the-edge-of-legibility/"
              target="__blank"
              rel="noopener noreferrer"
            >
              Art21
            </a>{" "}
            and instantly felt a jolt, an electric connection.{" "}
          </p>
          <p>
            I find it impressive the way she manages to combine her thorough and
            lucid ideas about the world (her lived experience specifically, and
            art-making generally) with a very sharp aesthetic sensibility. She’s
            as careful about the letterforms and typefaces themselves as she is
            about dialing in how abstract or face-value the work ends up.{" "}
          </p>
          <p>
            I also just think it’s cool whenever anybody uses letters and/or
            words in visual art, cuz I imagine it’s somehow less alienating for
            people who don’t think they “get art”. I make that assumption based
            on the fact that many of us learn our ABC’s early in life — it’s a
            formative experience — ergo when someone who might feel aggravated
            by a Jackson Pollock splatter painting encounters a piece that
            centers letters or words instead, they might find that a useful
            anchor, or “an invitation to go slow, instead of trying to rush to
            understanding” as the artist herself puts it. Letters can be for
            more than communication, they can be an on-ramp to the often-wacky
            and sometimes-very-cool world of art.
          </p>
          <p>
            I won’t flatter myself by making any artistic comparison between
            myself and Kameelah Janan Rasheed, but I do aspire to work with her
            precision and at the scale that she does. Where I will compare us is
            in the religious practice that we both inherited, which aptly began
            centuries ago with the word “READ”.{" "}
          </p>
          <p className="highlight">Rich Hickey</p>
          <p>
            This guy invented a useful programming language that I’m learning
            called Clojure.{" "}
          </p>
          <p>
            The story goes, in his own words: “I started working on Clojure in
            2005, during a sabbatical I funded out of retirement savings. The
            purpose of the sabbatical was to give myself the opportunity to work
            on whatever I found interesting, without regard to outcome,
            commercial viability or the opinions of others.” That’s.......simply
            flames lmao I’ve always dreamt of taking a sabbatical with that
            exact purpose (although I somehow doubt mine would yield such a{" "}
            <a
              href="https://insights.stackoverflow.com/survey/2021#most-loved-dreaded-and-wanted-language-love-dread"
              target="__blank"
              rel="noopener noreferrer"
            >
              beloved
            </a>{" "}
            piece of technology).
          </p>
          <p>
            This may surprise some folks, but you don’t necessarily have to give
            a fuck about words at all to create a programming language. But, as
            you may have guessed, Rich Hickey absolutely does. In fact, he loves
            words, so much so that he can’t give a single one of his (very
            enlightening) talks without carefully analyzing the definition and
            uses of at least one. He’s as great a communicator as he is a
            technologist, so it tracks that words mean a lot to him. I think
            there’s a strong argument that without his logophilia to help him
            proselytize the language, Clojure would have had a harder time
            picking up steam, despite its sheer technical brilliance.
          </p>
          <p>
            He’s also just a cool character, at this point my brain files him
            under “legendary old white dudes” along with Bill Murray and Rick
            Rubin.
          </p>
          <p>-------------</p>
          <p>
            These two make for such an interesting duo — seemingly worlds apart,
            but bound together by a love for the symbols that we cram meanings
            into and exchange in all kinds of ways. I repeat, excellent company!
            If either of y’all are reading this, you’re always welcome to{" "}
            <a
              href="http://shoya.co/shoyaright/vol1/mt-shoya"
              target="__blank"
              rel="noopener noreferrer"
            >
              Mt. Shoya
            </a>
            .{" "}
          </p>
          <p>
            Thanks for reading, I hope you liked some of the words I used :){" "}
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020-</p>
        </div>
      </div>
    </div>
  )
}
export default Logophilia
